import moment from "moment";
export const IsPC = (): boolean => {
  const userAgentInfo: string = navigator.userAgent;
  const Agents: string[] = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
export const intervalBeijingTime = (str: string): string => {
  const num = new Date(new Date().getTime());
  const Timestamp: any = moment(num);
  const dec = moment(
    new Date(
      new Date(Timestamp).toLocaleString("en", { timeZone: "Asia/Shanghai" })
    )
  );
  return dec.format(str);
};
