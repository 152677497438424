import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import App from "../App.vue";
// 根据平台展示组件
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: App,
  },
  {
    path: "/mobile",
    name: "mobileHome",
    component: () => import("../views/MobileHome.vue"),
  },
  {
    path: "/pc",
    name: "pcHome",
    component: () => import("../views/PcHome.vue"),
    children: [
      {
        path: "insBall",
        name: "insBall",
        component: () => import("@/rules/insBall/index.vue"),
        meta: {
          title: "一般体育说明",
        },
      },
      {
        path: "footBall",
        name: "football",
        component: () => import("@/rules/football/index.vue"),
        meta: {
          title: "足球",
        },
      },
      {
        path: "basketBall",
        name: "basketBall",
        component: () => import("@/rules/basketball/index.vue"),
        meta: {
          title: "篮球",
        },
      },
      {
        path: "tennisBall",
        name: "tennisBall",
        component: () => import("@/rules/tennisBall/index.vue"),
        meta: {
          title: "网球",
        },
      },
      {
        path: "vBall",
        name: "vBall",
        component: () => import("@/rules/vball/index.vue"),
        meta: {
          title: "排球",
        },
      },
      {
        path: "handBall",
        name: "handBall",
        component: () => import("@/rules/handBall/index.vue"),
        meta: {
          title: "手球",
        },
      },
      {
        path: "gofBall",
        name: "gofBall",
        component: () => import("@/rules/gofBall/index.vue"),
        meta: {
          title: "高尔夫球",
        },
      },
      {
        path: "TableBall",
        name: "tableBall",
        component: () => import("@/rules/tableBall/index.vue"),
        meta: {
          title: "桌球",
        },
      },
      {
        path: "badMintonBall",
        name: "badMintonBall",
        component: () => import("@/rules/badmintonBall/index.vue"),
        meta: {
          title: "羽毛球",
        },
      },
      {
        path: "pingpBall",
        name: "PingpBall",
        component: () => import("@/rules/pingpBall/index.vue"),
        meta: {
          title: "乒乓球",
        },
      },
      {
        path: "championBall",
        name: "championBall",
        component: () => import("@/rules/championBall/index.vue"),
        meta: {
          title: "冠军",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(): any {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
