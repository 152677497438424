
interface DataProps {
  platform: string;
  show: boolean;
  loading: boolean;
}
import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { IsPC } from "@/utils";
import LoadingCom from "@/components/loading/index.vue";
export default defineComponent({
  components: {
    LoadingCom,
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const data: DataProps = reactive({
      platform: IsPC() ? "pc-platform" : "mobile-platform",
      show: false,
      loading: true,
    });
    // if (IsPC()) {
    //   router.push("/pc/insBall");
    // } else {
    //   router.push({
    //     path:"/mobile",
    //     query:{
    //       clientItem:route.query.clientItem
    //     }
    //   });
    // }
    setTimeout(() => {
      data.loading = false;
    }, 500);
    return {
      data,
    };
  },
});
