<template>
  <div class="loading">
    <div>
      <img src="~@/assets/imgs/loading.gif" alt="" />
      <div class="text">正在加载中....请稍后</div>
    </div>
  </div>
</template>

<script lang="ts"></script>

<style scoped lang="stylus">
.loading{
  position:absolute;
  top:0;
  left:0;
  z-index:300;
  height:100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    width: 48px;
  }
  .text{
    font-size: 14px;
    color: #888888;
    padding-top: 10px;
  }
}
</style>
